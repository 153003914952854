import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'open', 'closed' ]

  openMenu() {
    this.element.classList.add(this.openClass)
    this.element.classList.remove(this.closedClass)
  }

  closeMenu() {
    this.element.classList.remove(this.openClass)
    this.element.classList.add(this.closedClass)
  }
}
