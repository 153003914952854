
        import * as module0 from './controllers/banner_controller.js';import * as module1 from './controllers/blog_card_controller.js';import * as module2 from './controllers/contact_controller.js';import * as module3 from './controllers/details_event_controller.js';import * as module4 from './controllers/height_controller.js';import * as module5 from './controllers/nav_controller.js';import * as module6 from './controllers/scroll_controller.js'
        const modules = {
            "./controllers/banner_controller.js": module0,
            "./controllers/blog_card_controller.js": module1,
            "./controllers/contact_controller.js": module2,
            "./controllers/details_event_controller.js": module3,
            "./controllers/height_controller.js": module4,
            "./controllers/nav_controller.js": module5,
            "./controllers/scroll_controller.js": module6,
        };
        export default modules;
      